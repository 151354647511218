import {
  Button,
  Col,
  Input,
  message,
  Result,
  Row,
  Space,
  Select,
  Checkbox,
  Switch,
  InputNumber,
} from 'antd';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Api } from '../api';
import { useSubmitForm } from '../helpers/forms';
import { ImagesHelpers } from '../helpers/images';
import { useLoadFormData } from '../hooks/useLoadFormData';
import { FieldErrorMessage } from './FieldErrorMessage';
import { FileUploader } from './FileUploader';
import { useCategoriesList } from '../hooks/useCategoriesList';
import { TagsInput } from './TagsInput';
import { useTagsList } from '../hooks/useTagsList';
import MoneyInput from './MoneyInput';
import TextEditorInput from './TextEditorInput';
import ItemOptionsField from './Items/ItemOptionsField';
import ItemVariationField from './Items/ItemVariationField';
import PriceInput from './PriceInput';
import FileUploadV2 from './fileUploadV2/FileUploadV2';
import YoutubeVideosArray from './YoutubeVideosField';
import CategorySelector from '../components/CategorySelector';
import BrandSelector from '../components/BrandSelector';
import TabsCreatorField from './TabsCreatorField';
import ItemOptionMeta from './Items/ItemOptionMeta';
import { useItemOptionsList } from '../api/useItemOptionsList';

const ItemForm = () => {
  let { id }: any = useParams();
  // const [notFound, setNotFound] = useState(false);
  const [optionsList, loadingItemOptions] = useItemOptionsList();
  const { handleSubmit, errors, control, reset, watch, setValue } = useForm({
    defaultValues: { options: [{}], images: [], videos: [] },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'options', // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    },
  );
  const variationsFieldHooks = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'variations', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const optionsArrayFieldHooks = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'options', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const optionsValue = useWatch({
    control,
    name: 'options',
    defaultValue: [],
  });
  let hasVariations = useWatch({
    control,
    name: 'hasVariations',
    defaultValue: false,
  });

  useEffect(() => {
    if (optionsValue?.[0]?.values) {
      console.log('reseting variations');
      setValue('variations', []);
      let variations = optionsValue[0].values.map((value) => ({
        options: [{ option: optionsValue[0].option, value }],
      }));
      for (let i = 1; i < optionsValue.length; i++) {
        if (!optionsValue[i].values) continue;
        const newVariations = [];
        for (const variation of variations) {
          for (const optionValue of optionsValue[i].values) {
            newVariations.push({
              options: [
                ...variation.options,
                { option: optionsValue[i].option, value: optionValue },
              ],
            });
          }
        }
        variations = newVariations;
      }
      setValue('variations', variations);
    }
  }, [JSON.stringify(optionsValue)]);
  let history = useHistory();
  const [categories, loadingCategories] = useCategoriesList();
  const [tags, loadingTags] = useTagsList();
  const onSuccess = () => {
    message.success('Saved');
    history.push('/items');
  };
  const imageFields = [
    {
      name: 'images',
      singleValue: false,
    },
  ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.items.single,
    id,
    setValue: reset,
    // imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.items.create,
    updateApiCall: Api.items.update,
    onSuccess,
    // imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/items">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'name'}>Name</label>
            <Controller
              control={control}
              as={<Input id={'name'} />}
              rules={{ required: true }}
              name="name"
            />
            <FieldErrorMessage errors={errors} name="name" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={24}>
            <label htmlFor={'description'}>Description</label>
            <Controller
              control={control}
              as={TextEditorInput}
              rules={{ required: false }}
              name="description"
            />
            <FieldErrorMessage errors={errors} name="description" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={8}>
            <label htmlFor={'slogan'}>Slogan</label>
            <Controller
              control={control}
              as={<Input.TextArea id={'slogan'} />}
              rules={{ required: false }}
              name="slogan"
            />
            <FieldErrorMessage errors={errors} name="slogan" />
          </Col>
        </Row>
        <div>
          {/* <span style={{ color: "#6F7287" }} className="text-sm">Extra Info Tabs</span> */}
          <TabsCreatorField
            control={control}
            errors={errors}
            fieldName="tabs"
          />
        </div>
        {!id && (
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <label htmlFor={'hasVariations'}>
                Has Variations (multiple sizes/colors)
              </label>
              <Controller
                style={{ display: 'block' }}
                // onChange={([e]: any) => e.target.checked}
                control={control}
                render={({ onChange, value }) => (
                  <Switch onChange={onChange} checked={value} />
                )}
                name="hasVariations"
              />
              <FieldErrorMessage errors={errors} name="hasVariations" />
            </Col>
          </Row>
        )}

        {!id && !hasVariations && (
          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'sku'}>SKU</label>
              <Controller
                control={control}
                as={<Input id={'sku'} />}
                rules={{ required: true }}
                name="sku"
              />
              <FieldErrorMessage errors={errors} name="sku" />
            </Col>
            <Col span={6}>
              <label htmlFor={'price'}>Price</label>
              <Controller
                control={control}
                as={<PriceInput style={{ width: '100%' }} />}
                rules={{ required: true }}
                name="price"
              />
              <FieldErrorMessage errors={errors} name="price" />
            </Col>
            <Col span={6}>
              <label htmlFor={'quantity'}>Quantity</label>
              <Controller
                control={control}
                as={<InputNumber style={{ width: '100%' }} />}
                rules={{ required: true }}
                name="quantity"
              />
              <FieldErrorMessage errors={errors} name="quantity" />
            </Col>
          </Row>
        )}
        <Row style={{ width: '100%' }} gutter={12}>
          {!id && hasVariations && (
            <>
              <Col span={24}>
                <ItemOptionsField
                  fieldValue={optionsValue}
                  fields={fields}
                  append={append}
                  control={control}
                  remove={remove}
                  errors={errors}
                />
              </Col>
              <Col span={24}>
                <ItemOptionMeta
                  fieldHooks={optionsArrayFieldHooks}
                  control={control}
                  errors={errors}
                  options={optionsValue}
                  optionsList={optionsList}
                  loading={loadingItemOptions}
                />
              </Col>
              <Col style={{ marginTop: 10 }} span={24}>
                <ItemVariationField
                  fieldHooks={variationsFieldHooks}
                  control={control}
                  errors={errors}
                />
              </Col>
            </>
          )}
        </Row>

        <Row gutter={6}>
          <Col span={6}>
            <label htmlFor={'category'}>Category</label>
            <Controller
              control={control}
              as={<CategorySelector />}
              rules={{
                required: true,
              }}
              name="category"
            />
            <FieldErrorMessage errors={errors} name="category" />
          </Col>
          <Col span={6}>
            <label htmlFor={'tags'}>Tags</label>
            <Controller
              control={control}
              as={<TagsInput style={{ width: '100%' }} tags={tags} />}
              rules={{
                required: true,
              }}
              name="tags"
            />
            <FieldErrorMessage errors={errors} name="tags" />
          </Col>
        </Row>

        <Row gutter={6}>
          <Col span={12}>
            <label htmlFor={'images'}>Images</label>
            <Controller
              control={control}
              as={<FileUploadV2 max={5} />}
              // rules={{
              //     validate: ImagesHelpers.validate
              // }}
              name="images"
            />
            <FieldErrorMessage errors={errors} name="images" />
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={12}>
            <label htmlFor={'thumbnailImages'}>Thumbnail Images</label>
            <Controller
              control={control}
              name="thumbnailImages"
              rules={{
                validate: (value) =>
                  (value && value.length >= 2) ||
                  'At least 2 thumbnail images are required',
              }}
              render={({ onChange, value }) => (
                <FileUploadV2 max={2} value={value} onChange={onChange} />
              )}
            />
            <FieldErrorMessage errors={errors} name="thumbnailImages" />
          </Col>
        </Row>

        {/* <Row>
                    <Col span={12}>


                        <YoutubeVideosArray control={control} errors={errors} fieldName="videos" />

                    </Col>
                </Row> */}
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </form>
  );
};

export default ItemForm;
