import {
  BoxPlotOutlined,
  DashboardOutlined,
  FormOutlined,
  PlusOutlined,
  TagsOutlined,
  TeamOutlined,
} from '@ant-design/icons/lib';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Api } from './api';
import ItemForm from './components/ItemForm';
import ItemSinglePage from './components/Items/ItemSinglePage';
import ItemTable from './components/ItemTable';
import TagForm from './components/TagForm';
import TagTable from './components/TagTable';
import ContactFormTable from './components/ContactFormTable';
import ContactFormForm from './components/ContactFormForm';
import PageTable from './components/PageTable';
import PageForm from './components/PageForm';
const CategoriesForm = React.lazy(
  () => import('./components/CategoriesCreate'),
);
const CategoriesTable = React.lazy(() => import('./components/CategoriesList'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const UsersForm = React.lazy(() => import('./components/UsersCreate'));
const UsersList = React.lazy(() => import('./components/UsersList'));

export const Routes: any = [
  {
    title: 'Dashboard',
    component: Dashboard,
    icon: DashboardOutlined,
    hideFromMenu: false,
    breadcrumb: 'I love sandwiches',
    path: '/',
    isHomePage: true,
  },
  {
    title: 'Users',
    icon: TeamOutlined,
    path: '/users',
    buttons: [
      <Link key="create" to="/users/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add User
        </Button>
      </Link>,
    ],
    component: UsersList,
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/users/create',
        component: UsersForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/users/update/:id',
        component: UsersForm,
      },
    ],
  },
  {
    title: 'Tags',
    icon: TagsOutlined,
    path: '/tags',
    dataRoute: Api.tags.statistics,
    component: TagTable,
    buttons: [
      <Link key="create" to="/tags/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Tag
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/tags/create',
        component: TagForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/tags/update/:id',
        component: TagForm,
      },
    ],
  },
  // {
  //   title: "Brands",
  //   icon: TagOutlined,
  //   path: "/brands",
  //   dataRoute: Api.brands.statistics,
  //   component: BrandTable,
  //   buttons: [
  //     <Link key="create" to="/brands/create">
  //       <Button type="primary" icon={<PlusOutlined />}>
  //         Add Brand
  //       </Button>
  //     </Link>,
  //   ],
  //   routes: [
  //     {
  //       title: "Create",
  //       icon: PlusOutlined,
  //       path: "/brands/create",
  //       component: BrandForm,
  //     },
  //     {
  //       title: "Update",
  //       hideFromMenu: true,
  //       path: "/brands/update/:id",
  //       component: BrandForm,
  //     },
  //   ],
  // },
  {
    title: 'Categories',
    icon: TagsOutlined,
    path: '/categories',
    dataRoute: Api.categories.statistics,
    component: CategoriesTable,
    buttons: [
      <Link key="create" to="/categories/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Category
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/categories/create',
        component: CategoriesForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/categories/update/:id',
        component: CategoriesForm,
      },
    ],
  },
  {
    title: 'Pages',
    icon: TagsOutlined,
    buttons: [],
    path: '/pages',
    dataRoute: Api.pages.statistics,
    component: PageTable,
    routes: [
      {
        title: 'Page Management',
        hidePageHeader: true,
        hideFromMenu: true,
        path: '/pages/update/:id',
        component: PageForm,
      },
    ],
  },
  {
    title: 'Items',
    icon: BoxPlotOutlined,
    path: '/items',
    dataRoute: Api.items.statistics,
    component: ItemTable,
    buttons: [
      <Link key="create" to="/items/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Item
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/items/create',
        component: ItemForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/items/update/:id',
        component: ItemSinglePage,
      },
    ],
  },
  {
    title: 'Contact Forms',
    icon: FormOutlined,
    path: '/contactForms',
    dataRoute: Api.contactForms.statistics,
    component: ContactFormTable,
    routes: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/contactForms/update/:id',
        component: ContactFormForm,
      },
    ],
  },
  // {
  //   title: "Email Subscriptions",
  //   icon: MailOutlined,
  //   path: "/email-subscriptions",
  //   dataRoute: Api.emailSubscriptions.statistics,
  //   component: EmailSubscriptionTable,
  //   routes: [
  //     {
  //       title: "Update",
  //       hideFromMenu: true,
  //       path: "/email-subscriptions/update/:id",
  //       component: EmailSubscriptionForm,
  //     },
  //   ],
  // },
  // {
  //   title: "Orders",
  //   icon: BoxPlotOutlined,
  //   path: "/orders",
  //   dataRoute: Api.orders.statistics,
  //   component: OrderTable,
  //   routes: [
  //     {
  //       title: "Update Order",
  //       hideFromMenu: true,
  //       path: "/orders/update/:id",
  //       component: OrderSinglePage,
  //     },
  //   ],
  // },
  // {
  //   title: "Promo Codes",
  //   icon: TagOutlined,
  //   path: "/promoCodes",
  //   dataRoute: Api.promoCodes.statistics,
  //   component: PromoCodeTable,
  //   buttons: [
  //     <Link key="create" to="/promoCodes/create">
  //       <Button type="primary" icon={<PlusOutlined />}>
  //         Add Promo Code
  //       </Button>
  //     </Link>,
  //   ],
  //   routes: [
  //     {
  //       title: "Create",
  //       icon: PlusOutlined,
  //       path: "/promoCodes/create",
  //       component: PromoCodeForm,
  //     },
  //     {
  //       title: "Update",
  //       hideFromMenu: true,
  //       path: "/promoCodes/update/:id",
  //       component: PromoCodeForm,
  //     },
  //   ],
  // },
];
