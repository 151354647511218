import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FieldErrorMessage } from '../FieldErrorMessage';
import ItemOptionPicker from './ItemOptionPicker';

const ItemOptionsField: React.FC<any> = ({
  fields,
  append,
  control,
  remove,
  errors,
  fieldValue,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <label>Options</label>
      <Row gutter={4} align="middle">
        {fields.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Col span={9}>
              <label style={{ fontSize: '0.8rem' }}>Option</label>
              <Controller
                as={
                  <ItemOptionPicker
                    selectedOptions={
                      fieldValue
                        ? fieldValue
                            .filter((i: any, ind: any) => ind !== index)
                            .map((f: any) => {
                              return f.option;
                            })
                        : []
                    }
                  />
                }
                name={`options[${index}].option`}
                defaultValue={item.option} // make sure to set up defaultValue
                control={control}
                rules={{ required: 'Option required' }}
              />
              <FieldErrorMessage
                errors={errors}
                name={`options[${index}].option`}
              />
            </Col>
            <Col span={9}>
              <label style={{ fontSize: '0.8rem' }}>Values</label>
              <Controller
                as={
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Values"
                  />
                }
                name={`options[${index}].values`}
                defaultValue={item.values} // make sure to set up defaultValue
                control={control}
                rules={{ required: 'Values required' }}
              />
              <FieldErrorMessage
                errors={errors}
                name={`options[${index}].values`}
              />
            </Col>

            {index > 0 && (
              <Col style={{ marginTop: 'auto' }} span={2}>
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  icon={<DeleteOutlined />}
                ></Button>
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
      <Button
        onClick={() => {
          append({});
        }}
        style={{ marginTop: 5 }}
        size="small"
        icon={<PlusOutlined />}
      >
        Add Option
      </Button>
      <FieldErrorMessage errors={errors} name="options" />
    </div>
  );
};

export default ItemOptionsField;
