import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FieldErrorMessage } from '../FieldErrorMessage';
import FileUploadV2 from '../fileUploadV2/FileUploadV2';
import PriceInput from '../PriceInput';

const ItemVariationField: React.FC<any> = ({ fieldHooks, control, errors }) => {
  const { fields, append, prepend, remove, swap, move, insert } = fieldHooks;
  return (
    <div style={{ width: '100%' }}>
      <label>Variations</label>

      {fields.map((item: any, index: number) => (
        <Card
          title={item.options.map((op: any, index: number) => (
            <span>
              {op.value} <span>
                {index + 1 !== item.options.length && '/'}
              </span>{' '}
            </span>
          ))}
          key={item.id}
        >
          <Row gutter={4} align="middle">
            <Col span={0} style={{ visibility: 'hidden' }}>
              <Controller
                as={<Input hidden />}
                name={`variations[${index}].options`}
                defaultValue={item.options} // make sure to set up defaultValue
                control={control}
              />
            </Col>
            <Col span={6}>
              <label style={{ fontSize: '0.8rem' }}>SKU</label>
              <Controller
                as={<Input placeholder="SKU (Stock Keeping Unit)" />}
                name={`variations[${index}].sku`}
                defaultValue={item.sku} // make sure to set up defaultValue
                rules={{ required: true }}
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`variations[${index}].sku`}
              />
            </Col>

            <Col span={6}>
              <label style={{ fontSize: '0.8rem' }}>Price</label>
              <Controller
                as={
                  <PriceInput
                    style={{ display: 'block', width: '100%' }}
                    placeholder="Price"
                  />
                }
                name={`variations[${index}].price`}
                rules={{ required: true }}
                defaultValue={item.price} // make sure to set up defaultValue
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`variations[${index}].price`}
              />
            </Col>
            <Col span={6}>
              <label style={{ fontSize: '0.8rem' }}>Quantity</label>
              <Controller
                as={
                  <InputNumber
                    style={{ display: 'block', width: '100%' }}
                    placeholder="Quantity"
                  />
                }
                name={`variations[${index}].quantity`}
                defaultValue={item.quantity} // make sure to set up defaultValue
                rules={{ required: true }}
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`variations[${index}].quantity`}
              />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: '0.8rem' }}>Weight</label>
              <Controller
                as={
                  <InputNumber style={{ width: '100%' }} placeholder="Weight" />
                }
                name={`variations[${index}].shipping.weight`}
                defaultValue={item.sku} // make sure to set up defaultValue
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`variations[${index}].shipping.weight`}
              />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: '0.8rem' }}>Width</label>
              <Controller
                as={
                  <InputNumber style={{ width: '100%' }} placeholder="width" />
                }
                name={`variations[${index}].shipping.width`}
                defaultValue={item.sku} // make sure to set up defaultValue
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`options[${index}].shipping.width`}
              />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: '0.8rem' }}>Height</label>
              <Controller
                as={
                  <InputNumber style={{ width: '100%' }} placeholder="Height" />
                }
                name={`variations[${index}].shipping.height`}
                defaultValue={item.sku} // make sure to set up defaultValue
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`options[${index}].shipping.height`}
              />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: '0.8rem' }}>Length</label>
              <Controller
                as={
                  <InputNumber style={{ width: '100%' }} placeholder="Length" />
                }
                name={`variations[${index}].shipping.length`}
                defaultValue={item.sku} // make sure to set up defaultValue
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`options[${index}].shipping.length`}
              />
            </Col>

            <Col span={24}>
              <label style={{ fontSize: '0.8rem' }}>Images</label>
              <Controller
                as={<FileUploadV2 max={5} />}
                name={`variations[${index}].images`}
                defaultValue={item.sku} // make sure to set up defaultValue
                control={control}
              />
              <FieldErrorMessage
                errors={errors}
                name={`options[${index}].images`}
              />
            </Col>

            {index > 0 && (
              <Col style={{ marginTop: 'auto' }} span={2}>
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  icon={<DeleteOutlined />}
                ></Button>
              </Col>
            )}
          </Row>
        </Card>
      ))}
      <FieldErrorMessage errors={errors} name="variations" />
    </div>
  );
};

export default ItemVariationField;
