import { Col, Divider, Input, Popover, Row, Typography } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import { SketchPicker } from 'react-color';
import { Base_Url } from '../../api';
import { store } from '../../store';
import FileUploadV2 from '../fileUploadV2/FileUploadV2';

const ItemOptionMeta: React.FC<any> = ({
  options,
  optionsList,
  loading,
  fieldHooks,
  control,
}) => {
  //   const { fields, append, prepend, remove, swap, move, insert } = fieldHooks;
  if (loading) return null;

  let items =
    options && options[0] && optionsList
      ? options.map((option: any) => {
          return {
            values: option.values,
            option:
              optionsList &&
              optionsList[0] &&
              optionsList.find((opt: any) => opt._id === option?.option),
          };
        })
      : [];

  return (
    <div>
      {items.map(({ values, option }: any, index: number) => {
        if (option?.label === 'Color')
          return (
            <React.Fragment>
              <Divider orientation="left">Color Selector</Divider>
              <Row gutter={8}>
                {values &&
                  values.map((color: string, colorIndex: number) => (
                    <Col span={4}>
                      <Typography.Paragraph
                        style={{ textTransform: 'capitalize' }}
                      >
                        {color}
                      </Typography.Paragraph>
                      <Row gutter={8}>
                        <Col span={24}>
                          <Controller
                            control={control}
                            name={`options[${index}].meta[${colorIndex}].hex`}
                            defaultValue={color} // make sure to set up defaultValue
                            render={({ onChange, value }) => (
                              <Popover
                                content={
                                  <SketchPicker
                                    onChangeComplete={(color) =>
                                      onChange(color?.hex)
                                    }
                                    color={value}
                                  />
                                }
                                title="Color Picker"
                              >
                                <div
                                  style={{
                                    width: 40,
                                    height: 40,
                                    background: value,
                                  }}
                                ></div>
                              </Popover>
                            )}
                          />
                        </Col>
                        <Col span={24}>
                          <Controller
                            control={control}
                            as={<FileUploadV2 singleValue max={1} />}
                            // rules={{
                            //     validate: ImagesHelpers.validate
                            // }}
                            name={`options[${index}].meta[${colorIndex}].materialImage`}
                          />
                        </Col>
                      </Row>
                      <Controller
                        control={control}
                        name={`options[${index}].meta[${colorIndex}].value`}
                        defaultValue={color} // make sure to set up defaultValue
                        render={({ onChange, value }) => <div />}
                      />
                    </Col>
                  ))}
              </Row>
            </React.Fragment>
          );
        return null;
      })}
    </div>
  );
};

export default ItemOptionMeta;
